import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Git #101 tips and best practices",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:56:47.000Z",
  "url": "/2018/02/git-101-tips-and-best-practices/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Git is an amazing tool, and I can surely say it saved my life for several times during my career.`}</p>
    <h6>{`adding code {#addingcode}`}</h6>
    <pre><code parentName="pre" {...{}}>{`#create a new commit
git commit -am "some commit msg"
`}</code></pre>
    <h6>{`Updating code {#updatingcode}`}</h6>
    <pre><code parentName="pre" {...{}}>{`# push your code to main repo
git push

# update your code from main repo
git push
`}</code></pre>
    <h6>{`Checkout branches {#checkoutbranches}`}</h6>
    <pre><code parentName="pre" {...{}}>{`# checkout to the previous branch
git checkout -

#checkout to specific branch
git checkout BRANCH_NAME

#checkout to a NEW branch
git checkout -b BRANCH_NAME
`}</code></pre>
    <h6>{`Delete local branch: {#deletelocalbranch}`}</h6>
    <pre><code parentName="pre" {...{}}>{`git branch -D BRANCH_NAME
#OR
git branch -d BRANCH_NAME
`}</code></pre>
    <p>{`The difference is `}{`“`}{`d`}{`”`}{` will need the branch to be merged, and sometimes it bugs you if you squash the merge or whatever, so when Im deleting a branch I`}{`’`}{`m sure the code is safe so I simply use `}{`“`}{`D`}{`”`}{`.`}</p>
    <h6>{`Delete remote branch: {#deleteremotebranch}`}</h6>
    <pre><code parentName="pre" {...{}}>{`git push origin :BRNACH_NAME
`}</code></pre>
    <h6>{`Delete several branches at once: {#deleteseveralbranchesatonce}`}</h6>
    <pre><code parentName="pre" {...{}}>{`git branch | grep PATTERN | xargs git branch -D
`}</code></pre>
    <p>{`PATTERN `}{`–`}{` is simply how to filter the branches you want to delete`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      